import React from 'react';
import CustomTooltip from '../CustomTooltip';

type OwnProps = {
    title: string;
    value: number | string;
}

const Detail = (props: OwnProps): React.ReactElement => {
    const { title, value } = props;
    return (
        <div className="mm-container__wrapper__description__content__wrapper__section-fix__fields-wrapper__input">
            <span className="medium-uppercase-blue-txt">{title}</span>
            <CustomTooltip title={`${value}`}>
                <div
                    className="detail"
                    data-testid={`detail-${title.toLowerCase().replace(' ', '-')}`}
                >
                    {value}
                </div>
            </CustomTooltip>
        </div>
    );
};

export default Detail;
