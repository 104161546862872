import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { CircularProgress } from '@material-ui/core';
import { ICON, SvgIcon } from '../SvgIcon';
import { MissingMasterdataEnum } from '../../../types/masterdata';
import { MissingMasterdataSummary } from '../../../constants/types';

interface OwnProps extends WithTranslation {
    missingMasterdata: MissingMasterdataSummary;
    percentageFixed: number;
    currentFixingType: MissingMasterdataEnum | null;
}

const MissingMasterdataModalSidebar = (props: OwnProps) => {
    const {
        missingMasterdata, t, percentageFixed, currentFixingType,
    } = props;
    return (
        <div className="mm-container__sidebar">
            <SvgIcon icon={ICON.SECURITY_UNSECURED} />
            <div className="mm-container__sidebar__types">
                {Object.keys(MissingMasterdataEnum).map((k) => {
                    return (
                        <div data-testid={`${currentFixingType === k ? 'selected-' : ''}sidebar-${k}`} key={k} className={`${currentFixingType === k ? 'selected' : ''}`}>
                            <p>{missingMasterdata[k]}</p>
                            <p>{t(`dashboard.missingMasterdataType.${k}`)}</p>
                        </div>
                    );
                })}
            </div>
            <div className="mm-container__sidebar__progress">
                <CircularProgress className="mm-container__sidebar__progress__bg" variant="determinate" size={83} thickness={6.5} value={100} />
                <CircularProgress variant="static" size={83} thickness={6.5} value={percentageFixed} />
                <div className="mm-container__sidebar__progress__label">
                    <p>{percentageFixed}%</p>
                    <p>{t('global.fixed')}</p>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(MissingMasterdataModalSidebar);
