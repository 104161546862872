import React from 'react';
import InputText from '../InputText';

type OwnProps = {
    title: string;
    value: string;
    name: string;
    maxLength: number;
    hasError: boolean;
    onChange(e: React.FormEvent<HTMLInputElement>): void;
}

const MissingMasterdataInput = (props: OwnProps): React.ReactElement => {
    const {
        title, value, name, maxLength, hasError, onChange,
    } = props;

    return (
        <div className="mm-container__wrapper__description__content__wrapper__section-fix__fields-wrapper__input">
            <span className="medium-uppercase-blue-txt">{title}</span>
            <InputText
                value={value}
                name={name}
                onChange={(e) => onChange(e)}
                maxLength={maxLength}
                hasError={hasError}
            />
        </div>
    );
};

export default MissingMasterdataInput;
